<template>
  <AuthHeader title="Driver Partner Portal" />
  <div class="mx-auto max-w-screen-md">
    <div class="-mt-3 flex min-h-full flex-col justify-center rounded-xl bg-white px-3 py-6">
      <form class="space-y-6" action="#" method="POST" :class="{ 'opacity-50': loading }" @submit.prevent="submit">
        <div>
          <label for="email" class="standard-field-label">Email address</label>
          <input
            id="email"
            v-model="credentials.email"
            name="email"
            type="email"
            autocomplete="email"
            required
            class="standard-text-input"
          />
        </div>
        <div>
          <label for="password" class="standard-field-label">Password</label>
          <input
            id="password"
            v-model="credentials.password"
            name="password"
            type="password"
            autocomplete="current-password"
            required
            class="standard-text-input"
            @input="resetError"
          />
        </div>

        <NuxtTurnstile
          ref="turnstile"
          v-model="credentials.ts_token"
          :options="{ 'error-callback': useTurnstileErrorHandler }"
        />

        <p v-if="error" class="mt-2 text-sm text-red-600">{{ error }}</p>

        <div>
          <button type="submit" class="btn-pink">
            <LoadingSpinner v-if="loading == true" />
            Sign in
          </button>
        </div>
      </form>
    </div>

    <ul role="list" class="divide-y divide-gray-200 px-3 py-0">
      <li class="relative flex justify-between gap-x-4 py-4">
        <div class="flex min-w-0 gap-x-3">
          <div class="min-w-0 flex-auto">
            <p class="flex text-xs leading-5 text-gray-700">
              <a href="#_" class="relative truncate hover:underline">Forgotten password?</a>
            </p>
            <p class="text-sm font-semibold leading-5 text-gray-900">
              <NuxtLink to="/login/reset">
                <span class="absolute inset-x-0 -top-px bottom-0"></span>
                Reset your password
              </NuxtLink>
            </p>
          </div>
        </div>
        <div class="flex shrink-0 items-center gap-x-3 pr-3">
          <ChevronRightIcon class="h-4 w-4 flex-none text-gray-900" />
        </div>
      </li>
      <li class="relative flex justify-between gap-x-4 py-4">
        <div class="flex min-w-0 gap-x-3">
          <div class="min-w-0 flex-auto">
            <p class="flex text-xs leading-5 text-gray-700">
              <a href="#_" class="relative truncate hover:underline">No Account?</a>
            </p>
            <p class="text-sm font-semibold leading-5 text-gray-900">
              <NuxtLink to="/register">
                <span class="absolute inset-x-0 -top-px bottom-0"></span>
                Create your account
              </NuxtLink>
            </p>
          </div>
        </div>
        <div class="flex shrink-0 items-center gap-x-3 pr-3">
          <ChevronRightIcon class="h-4 w-4 flex-none text-gray-900" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import type { LoginRequest, WithTsToken } from '~/api/types/Authentication'
import { ChevronRightIcon } from '@heroicons/vue/24/solid'

definePageMeta({
  layout: 'authentication',
  middleware: ['guest'],
})

const { login } = useAuth()
const credentials: LoginRequest & WithTsToken = reactive({
  email: '',
  password: '',
  remember: false,
  ts_token: '',
})

const error = ref<string>('')
const loading = ref<boolean>(false)
const turnstile = ref()

async function submit() {
  if (loading.value) {
    // prevent double submissions
    return
  }
  loading.value = true
  try {
    error.value = ''
    await login(credentials)
  } catch (e: any) {
    error.value = e.message as string
    turnstile.value?.reset()
  }
  loading.value = false
}

function resetError() {
  error.value = ''
}
</script>
